.vh-100 {
    height: 100vh;
}

.d-flex {
    display: flex;
}

.h1 {
    font-size: 2.5rem;
}